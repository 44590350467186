import React, { useState, useEffect, useContext } from 'react'

import SiteAccessContext from '../../../contexts/SiteAccessContext';

import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';
import { usePrivileges } from '../../../hooks/usePrivileges';

import gridLogo from "../../../Images/grid.svg";
import GomResponse from './GomResponse';
import '../../DataGrid.css';

// Styling
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import SampleByBlendForm from './SampleByBlendForm';
import SampleByNominationForm from './SampleByNominationForm';

function GomRequestGrid(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [plantDetails, setPlantDetails] = useState([]);
    const [plant, setPlant] = React.useState();
    const [formula, setFormula] = React.useState();
    const [formulas, setFormulas] = React.useState([]);
    const [fromDateLogged, setFromDateLogged] = React.useState();
    const [toDateLogged, setToDateLogged] = React.useState();
    const [openForm, setFormOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');
    const [ReqValue, SelectedReqValue] = React.useState([]);
    const { admin } = usePrivileges();
    const [openBlend, setOpenBlend] = React.useState(false);
    const [openNomination, setOpenNomination] = React.useState(false);

    const { assignedSites } = useContext(SiteAccessContext);

    const { setAlert } = useAlert();

    const [gomType, setgomType] = React.useState(0);
    const handleChangegomType = (event) => {
        setgomType(event.target.value);
    };

    const handleChangeplant = (event) => {
        setPlant(event.target.value);
        ReqValue.plant = event.target.value;
        SelectedReqValue(ReqValue);
        GetFormulaDetails();
    };

    const EditRow = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openEditUser(params.row);
                }}>Detail</button>
            </strong>
        )
    }
    var _column = [
        {
            field: 'blendDate',
            headerName: 'BlendDate',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'tank',
            headerName: 'Tank',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'blend',
            headerName: 'Blend',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'formula',
            headerName: 'Formula',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'language',
            headerName: 'Language',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'cmcsCode',
            headerName: 'CmcsCode',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'nominationKey',
            headerName: 'NominationKey',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'nominationItem',
            headerName: 'NominationItem',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'sequenceId',
            headerName: 'SequenceId',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'requestedBy',
            headerName: 'Requested By',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'requestedDate',
            headerName: 'Requested Date',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        }

    ];
    _column.push(
        {
            field: '-',
            headerName: 'Action (Detail)',
            width: 150,
            renderCell: EditRow,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: 'center'
        });

    var columns: GridColDef[] = _column;

    const openEditUser = (params) => {
        setSelectedValue(JSON.stringify(params).toString());
        setFormOpen(true);
    };
    const handleFormClose = (response) => {
        if (response != null && response == true) {
            LoadGomRequests();
        }
        setFormOpen(false);
        setOpenBlend(false);
        setOpenNomination(false);
    };
    const handleFromDateTextFieldChange = (event) => {
        setFromDateLogged(event.target.value)
    };

    const openAddByBlend = (params) => {
        setOpenBlend(true);
        setSelectedValue("");
    };
    const openAddByNomination = (params) => {
        setOpenNomination(true);
        setSelectedValue("");
    };

    const handleToDateTextFieldChange = (event) => {
        setToDateLogged(event.target.value)
    };
    const LoadGomRequests = async () => {

        const LoadMapFields = {
            plant: plant,
            formula: formula,
            fromDateLogged: fromDateLogged,
            toDateLogged: toDateLogged,
            gomType: gomType
        };

        await fetchDataAuthenticated(_apiUrl + 'GomRequestDetails', "POST", JSON.stringify(LoadMapFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then((response) => {
                setTableData(response);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });
    };

    const LoadplantDetails = async () => {

        let params = new URLSearchParams();

        if (assignedSites && assignedSites.length > 0) {

            assignedSites.map(si => params.append("sites", si.siteName));

            await fetchDataAuthenticated(_apiUrl + "PlantDetails?" + params.toString())
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then((data) => {
                    setPlantDetails(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                    console.error(error);
                });
        }
        else {
            console.error("In LoadplantDetails - You have no access to sites");
        }
    }
    const GetFormulaDetails = async () => {

        await fetchDataAuthenticated(_apiUrl + "GetFormulaDetailsByPlant/" + ReqValue.plant)
            .then(response => {
                if (response.ok && response.status == 200) {
                    return response.json();
                }
                else if(response.status == 204)
                {
                    return {};
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setFormulas(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    }


    useEffect(() => {
        LoadplantDetails();
    }, [assignedSites]);

    const getFromDate = () => {
        // Today
        let fromDate = new Date();

        return fromDate.toISOString().split('T')[0];
    }

    const getToDate = () => {
        // Today
        let fromDate = new Date();

        return fromDate.toISOString().split('T')[0];
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    Gom Requests
                </div>
                <div className='col-md-3'>
                    {/*TODO: implement this correctly: props.PostData.roleId != 3*/}
                    {admin && <button type="button" className="btn btn-outline-primary btn-primary-label" onClick={openAddByBlend}>GOM-Sample By Blend</button>}
                </div>
                <div className='col-md-3'>
                    {/*TODO: implement this correctly: props.PostData.roleId != 3*/}
                    {admin && <button type="button" className="btn btn-outline-primary btn-primary-label" onClick={openAddByNomination}>GOM-Sample By Nomination</button>}
                </div>
            </div>
            <div className='clearfix'></div>
            <div className='row'>

                <div className='col-md-2'>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="lblplantId">Plant</InputLabel>
                            <Select
                                labelId="lblplantId"
                                id="iplantId"
                                value={plant}
                                label="Plant"
                                onChange={handleChangeplant}
                            >
                                <MenuItem value="">Select Plant</MenuItem>
                                {plantDetails.map((plantdt, index) => {
                                    return (
                                        <MenuItem value={plantdt.plant} key={"lblplantId" + plantdt.plant + index}>{plantdt.plant}</MenuItem>
                                    );
                                })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="lblformulaId">Formula</InputLabel>
                            <Select
                                labelId="lblformulaId"
                                id="iformulaId"
                                value={formula}
                                label="Formula"
                            >
                                <MenuItem value="">Select Formula</MenuItem>
                                {formulas.map((formuladt, index) => {
                                    return (
                                        <MenuItem value={formuladt.formula} key={"lblformulaId" + formuladt.formula + index}>{formuladt.formula}</MenuItem>
                                    );
                                })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 0 },
                        }}
                    >
                        <TextField
                            id="fromDateLogged"
                            label="From Date Logged"
                            type="date"
                            defaultValue={getFromDate()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleFromDateTextFieldChange}

                        />
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 0 },
                        }}
                    >
                        <TextField
                            id="toDateLogged"
                            label="To Date Logged"
                            type="date"
                            defaultValue={getToDate()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleToDateTextFieldChange}

                        />
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="lblGomType">GOM Type</InputLabel>
                            <Select
                                labelId="lblGomType"
                                id="iGomType"
                                value={gomType}
                                onChange={handleChangegomType}
                                label="GOM Type"
                            >
                                <MenuItem value={1}>Sample By Blend</MenuItem>
                                <MenuItem value={2}>Sample By Nomination</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>

                <div className='col-md-2'>
                    {<button type="button" className="btn btn-outline-primary btn-primary-label" onClick={LoadGomRequests}>Filter</button>}
                </div>
            </div>
            <div className='clearfix'></div>
            <Box>
                <DataGrid autoHeight
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 3 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.gomRequestId}
                />
            </Box>

            <Dialog open={openForm} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">GOM Response</DialogTitle>
                <GomResponse SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
            <Dialog open={openBlend} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">GOM-Sample By Blend</DialogTitle>
                <SampleByBlendForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
            <Dialog open={openNomination} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">GOM-Sample By Nomination</DialogTitle>
                <SampleByNominationForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
        </div>
    );
}
export default GomRequestGrid;